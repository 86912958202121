import React from "react";
import Navbar from "./Navbar";
import bg from "../assets/bg.jpg";

const PageBanner = ({ title, img }) => {
  return (
    <>
      <Navbar color="nav-transparent" />
      <div
        className="d-flex justify-content-center 
        banner align-items-center"
        style={{
          height: "75vh",
          background:
            `rgba(0,0,0,0.35) 
            url(${img ? 
              img : 
              bg})`,
          backgroundBlendMode: "darken",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed"
        }}
      >
        <h1 className="text-white fw-bold mt-5 page-heading" style={{fontSize: "4rem"}}>{title}</h1>
      </div>
    </>
  );
};

export default PageBanner;
