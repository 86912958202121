import React, { useEffect, useRef, useState } from "react";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthContext } from "../Contexts/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import { AiFillBank, AiFillWarning } from "react-icons/ai";
import { toast } from "react-toastify";
import paypal from "../assets/paypal.png";
import { PiMoneyFill } from "react-icons/pi";
import { bankNames, relationships } from "../Data/banks"

const Payment = ({ }) => {
  const navigate = useNavigate()
  const { userdata, user } = useAuthContext();
  const messageRef = useRef();
  const [loading, setLoading] = useState(false);
  const [cuenta, setCuenta] = useState(true);
  const [ploading, setPloading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [terms1, setTerms1] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const [data, setData] = useState({ ptype: "Transfer" });
  const [cdata, setCData] = useState([]);
  const [benef, setBenef] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [Nom, setNOM] = useState("");
  const [order, setOrder] = useState({});
  const country = useRef();
  const countryInp = useRef();
  const curr2 = useRef();
  const exchangerate = useRef();
  const flag = useRef();
  const gets = useRef();
  const totalFees = useRef();
  const amountInp = useRef();
  const total = useRef();
  const ptype = useRef("Transfer");
  const [modal, setModal] = useState(user ? userdata.kyc: true);

  let countryData = [];
  const Fetch = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/rates/getrates"
    );

    setCData(data?.data);

    curr2.current.innerHTML = data.data[0].NOM;
    setNOM(data.data[0].NOM)
    countryInp.current.value = data.data[0].COUNTRY;
    exchangerate.current.innerHTML = data.data[0].EXCHRATE;
    flag.current.style.display = "flex";
    flag.current.src = data.data[0].FLAG;
    totalFees.current.innerHTML = data.data[0].FEE;
    countryData = data.data;

    const data2 = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/orders/userbenes",
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );

    const filterM = data2?.data?.orders.map((elem) => {
      return {
        id: elem._id,
        bbank: elem.bbank,
        bAccnumber: elem.bAccnumber,
        bname: elem.bname,
        cedula: elem.cedula,
      };
    });

    const isUnique = (arr, obj) => {
      return arr.some(
        (elem) =>
          elem.bbank === obj.bbank &&
          elem.bAccnumber === obj.bAccnumber &&
          elem.cedula === obj.cedula
      );
    };

    let uniqueFilterM;
    // if (cuenta) {
    uniqueFilterM = filterM.filter((elem, index, self) => {
      return (
        !isUnique(self.slice(0, index), elem)
      );
    });
    // } else {
    //   uniqueFilterM = filterM.filter((elem, index, self) => {
    //     return (
    //       elem.bAccnumber.length === 11 && !isUnique(self.slice(0, index), elem)
    //     );
    //   });
    // }

    setBenef(uniqueFilterM);

    setData((prev) => ({ ...prev, bbank: "BANESCO" }));
  };


  useEffect(() => {
    Fetch();
  }, []);

  const setCountry = (country, nom) => {
    curr2.current.innerHTML = nom;
    countryInp.current.value = country;

    setNOM(nom)


    setData((prev) => ({ ...prev, bbank: countryInp?.current?.value?.toLowerCase() === "venezuela" ? "BANESCO" : "Abuelos" }));

    countryData = cdata.filter((elem) => {
      return elem.NOM === nom && elem.COUNTRY === country;
    });

    totalFees.current.innerHTML = countryData[0].FEE;
    exchangerate.current.innerHTML = countryData[0].EXCHRATE + " " + nom;
    flag.current.style.display = "flex";
    flag.current.src = countryData[0].FLAG;
    amountInp.current.value = "";
    gets.current.value = "";
    total.current.value = "";
    Calculate();
  };


  const Submit = async () => {
    // e.preventDefault();
    if (
      (countryInp?.current?.value?.toLowerCase() === "venezeula" ? ((cuenta && data?.bAccnumber?.length === 20) ||
        (!cuenta && data?.bAccnumber?.length === 11)) : true)
    ) {
      if (userdata.kyc === true) {
        if (terms) {
          if (Object.values(userdata).length > 8) {
            if (amountInp.current.value.replaceAll(".", "").replaceAll(",", ".") >= 5) {
              setLoading(true);
              const resp = await axios.post(
                process.env.REACT_APP_BASE_URL + "/api/orders/new",
                {
                  date: new Date().getDate() +
                    "-" +
                    (new Date().getMonth() + 1) +
                    "-" +
                    new Date().getFullYear(),
                  userId: userdata._id,
                  name: userdata?.name + " " + userdata?.surname,
                  email: userdata.email,
                  phone: userdata.phone,
                  status: "Pendiente",
                  ...data,
                  totalAmount: total.current.value,
                  gets: gets.current.value,
                  NOM: Nom,
                  country: countryInp.current.value,
                  ptype: ptype.current
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    token: Cookies.get("token"),
                  },
                }
              );

              document.getElementById("bname").value = "";
              document.getElementById("cedula").value = "";
              document.getElementById("bAccnumber").value = "";
              document.getElementById("bbank").innerHTML = "BANESCO";
              document.getElementById("amount").value = "";
              document.getElementById("gets").value = "";
              document.getElementById("total").value = "";

              setData((prev) => ({ ...prev, bAccnumber: "", remarks: "" }));
              amountInp.current.value = "";
              gets.current.value = "";
              setOrder(resp.data.data);
              setShow(true);
              setTerms1(false)
              setTerms2(false)

              // toast.error(
              //   "AVISO: LOS PAGOS DEBEN REALIZARSE DESDE UNA CUENTA O TARJETA A TU NOMBRE. NO SE ACEPTAN PAGOS DE TERCEROS.", {
              //   position: "top-center",
              //   theme: "dark",
              //   autoClose: 7500
              // }
              // );

              setLoading(false);
            } else {
              toast.error(
                "Envíos solo a partir de 5,00€", {
                position: "top-center",
                theme: "dark"
              }
              );
              setLoading(false);
            }
          } else {
            toast.error("Necesitas completar tus datos personales en tu sección Mis Datos.", {
              position: "top-center",
              theme: "dark"
            });
          }
        } else {
          toast.error(
            "Acepta nuestros Términos y Condiciones", {
            position: "top-center",
            theme: "dark"
          }
          );
        }
      } else {
        toast.error(
          "¡USUARIO PENDIENTE DE VERIFICACIÓN DE IDENTIDAD!", {
          position: "top-center",
          theme: "dark"
        }
        );
      }
    } else {
      toast.error(
        (cuenta ? "CUENTA DEBE TENER 20  DíGITOS!" :
          "TELÉFONO DEBE TENER 11 DÍGITOS!"), {
        position: "top-center",
        theme: "dark"
      }
      )
    }
  };

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const CardFees = (amount) => {
    const fees =
      (amount
        ?.replaceAll(".", "dd")
        .replaceAll(",", "cc")
        .replaceAll("dd", "")
        .replaceAll("cc", ".")
        .replaceAll("€", "") *
        1.95) /
      100;
    let total = (
      Number(
        amount
          ?.replaceAll(".", "dd")
          .replaceAll(",", "cc")
          .replaceAll("dd", "")
          .replaceAll("cc", ".")
          .replaceAll("€", "")
      ) - Number(fees)
    ).toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return amount ? total : "";
  };


  const PaypalFees = (amount) => {
    const fees =
      (amount
        ?.replaceAll(".", "dd")
        .replaceAll(",", "cc")
        .replaceAll("dd", "")
        .replaceAll("cc", ".")
        .replaceAll("€", "") *
        3.5) /
      100;
    let total = (
      Number(
        amount
          ?.replaceAll(".", "dd")
          .replaceAll(",", "cc")
          .replaceAll("dd", "")
          .replaceAll("cc", ".")
          .replaceAll("€", "")
      ) - Number(fees)
    ).toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return amount ? total : "";
  };

  function Calculate() {
    countryData = cdata.filter((elem) => {
      return (
        elem.NOM === Nom &&
        elem.COUNTRY === countryInp.current.value
      );
    });

    if (countryData.length > 0) {
      let getAmount = 0;
      if (ptype.current === "Transfer") {
        getAmount = amountInp.current.value
      } else if (ptype.current === "Pago con Tarjeta") {
        getAmount = CardFees(amountInp.current.value)
      } else if (ptype.current === "Paypal") {
        getAmount = PaypalFees(amountInp.current.value)
      }
      let get = (
        getAmount.replaceAll(".", "").replaceAll(",", ".") *
        countryData[0].EXCHRATE.replaceAll(",", "cc").replaceAll(".", "dd")
          .replaceAll("cc", ".").replaceAll("dd", "")
      ).toLocaleString("en-En", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      gets.current.value = get.replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",")

      let totals = (
        Number(amountInp.current.value.replaceAll(".", "").replace(",", ".")) +
        Number(countryData[0].FEE.replace("€", "")
          .replaceAll(",", "cc").replaceAll(".", "dd")
          .replaceAll("cc", ".").replaceAll("dd", ""))
      ).toLocaleString("en-En", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      total.current.value = totals.replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");
    }
  }

  useEffect(() => {
    if (userdata?.kyc) {
      document.getElementById("mainform").disabled = false;
    }
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  const format = (e) => {
    var num = e.target.value
      .replaceAll(".", "")
      .replaceAll(" ", "")
      .replaceAll(",00", "")
      .replaceAll(",0", "");

    var num2 = num.split(/(?=(?:\d{3})+$)/).join(".");
    e.target.value = num2.replaceAll(",00", "").replaceAll(",0", "").replaceAll(",", "") + ",00";

    const cursorPosition = e.target.selectionStart;
    const commaPosition = e.target.value.indexOf(',');

    if (cursorPosition <= commaPosition) {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    } else {
      e.target.setSelectionRange(commaPosition, commaPosition);
    }
  };

  const handleChange2 = (event) => {
    const value = event.target.value;
    const onlyNumbers = value.replace(/[^0-9]/g, "");
    const limitedValue = onlyNumbers.slice(0, 20);

    setData((prev) => ({ ...prev, bAccnumber: limitedValue }));
  };

  const HandlePaid = async () => {
    setPloading(true);
    await axios.post(
      process.env.REACT_APP_BASE_URL + "/api/orders/paid",
      {
        name: userdata?.name,
        email: userdata.email,
        orderid: order.orderId,
        id: userdata.uid,
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );

    toast.success("Tu aviso ha sido enviado.", {
      position: "top-center",
      theme: "dark"
    });

    setPloading(false);
    setShow(false);
  };

  const handleAccountNumber = (e) => {
    if (countryInp?.current?.value?.toLowerCase() === "venezuela") {
      if (cuenta) {
        if (e.target.value.length <= 20) {
          handleChange(e);
        }
      } else {
        if (e.target.value.length <= 11) {
          handleChange(e);
        }
      }
    } else {
      handleChange(e)
    }
  }

  useEffect(() => {
    Fetch();
  }, [cuenta]);

  if (!user) return <Navigate to={"/login"} />;

  return (
    <div className="OrderSection">
      <PageBanner title="Hacer Envío" />
      <div className="px-0 profile_box mx-auto py-5 mt-10 bg-white rounded position-relative">
        <div className="calculator pb-0 mb-3">
          <div className="d-flex">
            <AiFillWarning color="red" size={35} />
            <p className="ms-2">
              Información: Utiliza este formulario para crear un nuevo Envío de
              Dinero.
            </p>
          </div>

          <form onSubmit={(e) => {
            e.preventDefault();
            if (
              countryInp?.current?.value?.toLowerCase() === "venezuela" ?
                (cuenta ? data?.bAccnumber?.length === 20 : data?.bAccnumber?.length === 11)
                : true
            ) {
              if (userdata.kyc === true) {
                if (terms) {
                  if (Object.values(userdata).length > 8) {
                    if (amountInp.current.value.replaceAll(".", "").replaceAll(",", ".") >= 5) {
                      setTerms1(true)
                    } else {
                      toast.error(
                        "Envíos solo a partir de 5,00€", {
                        position: "top-center",
                        theme: "dark"
                      }
                      );
                      setLoading(false);
                    }
                  } else {
                    toast.error("Necesitas completar tus datos personales en tu sección Mis Datos.", {
                      position: "top-center",
                      theme: "dark"
                    });
                  }
                } else {
                  toast.error(
                    "Acepta nuestros Términos y Condiciones", {
                    position: "top-center",
                    theme: "dark"
                  }
                  );
                }
              } else {
                toast.error(
                  "¡USUARIO PENDIENTE DE VERIFICACIÓN DE IDENTIDAD!", {
                  position: "top-center",
                  theme: "dark"
                }
                );
              }
            } else {
              toast.error(
                (cuenta ? "CUENTA DEBE TENER 20  DíGITOS!" :
                  "TELÉFONO DEBE TENER 11 DÍGITOS!"), {
                position: "top-center",
                theme: "dark"
              }
              )
            }
          }} className="calculator mt-3 pt-0">
            <fieldset disabled={"disabled"} id="mainform">
              <div className="mt-3">
                <label>Destino</label>
                <div
                  className="d-flex mt-1"
                  style={{ position: "relative", width: "100%" }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img id="flag" ref={flag} alt={`Latinotransfer `} />
                  <input
                    type="text"
                    className="form-control rounded-0"
                    id="countryInp"
                    ref={countryInp}
                    autoComplete="off"
                  />
                  <div className="dropdown" style={{ position: "static" }}>
                    <button
                      className="btn border dropdown-toggle rounded-0 h-100"
                      type="button"
                    ></button>
                    <ul className="dropdown-menu" id="country" ref={country}>
                      {cdata.map((elem) => {
                        return (
                          <li
                            key={elem.COUNTRY}
                            type="button"
                            className="py-1 px-2"
                            onClick={() =>
                              setCountry(`${elem.COUNTRY}`, `${elem.NOM}`)
                            }
                          >
                            {elem.COUNTRY}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {/*  */}
              {countryInp?.current?.value?.toLowerCase() !== "venezuela" && <p className="text-danger mt-3">
                <b>AVISO:</b> Los envíos a {countryInp?.current?.value} se realizan únicamente a través de la empresa <b>MoneyGram</b>, actuando Transferven como Agente Autorizado. Usted recibirá en su email un código, una vez que haya efectuado el pago y se haya verificado. Con este código, el beneficiario podrá retirar los fondos en cualquier sucursal <b>Moneygram</b> de manera presencial con su documento de identidad. Cualquier duda, contacte primero con Atención al Cliente.

              </p>}

              <label className="mt-3">Nombre y Apellido Beneficiario</label>
              <div className="dropdown w-100">
                <input
                  className="form-control rounded mt-1"
                  type="text"
                  placeholder=""
                  id="bname"
                  onChange={handleChange}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  autoComplete="off"
                  required
                />
                <ul className="dropdown-menu w-100">
                  {benef.map((elem) => {
                    return (
                      <li
                        className="dropdown-item"
                        type="button"
                        key={elem.bAccnumber}
                        onClick={(e) => {
                          setData((prev) => ({
                            ...prev,
                            bbank: elem.bbank,
                            bAccnumber: elem.bAccnumber,
                            bname: elem.bname,
                            cedula: elem.cedula,
                          }));
                          setCuenta(elem.bAccnumber.length === 20 ? true : false);
                          document.getElementById("bname").value = elem.bname;
                          document.getElementById("cedula").value = elem.cedula;
                          document.getElementById("bAccnumber").value =
                            elem.bAccnumber;
                          document.getElementById("bbank").value = elem.bbank;
                        }}
                      >
                        {elem.bname} | {elem.bbank}
                      </li>
                    );
                  })}
                </ul>
              </div>

              <label className="mt-3">Cédula Beneficiario</label>
              <input
                className="form-control rounded mt-1"
                type="text"
                id="cedula"
                onChange={handleChange}
                placeholder=""
                required={true}
                // inputMode="numeric"
              />


              <div className="mt-3">
                <label className="">{countryInp?.current?.value?.toLowerCase() === "venezuela" ? "Entidad Bancaria" : "Relación con Beneficiario"}</label>
                <div className="d-flex align-items-center rounded">
                  <div className="dropdown w-100">
                    <button
                      className="d-flex justify-content-between align-items-center px-3 py-2 border dropdown-toggle w-100 bg-white text-start"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="bbank"
                    >
                      {data.bbank}
                    </button>
                    <ul className="dropdown-menu w-100" style={{ height: "250px", overflowY: "auto" }}>
                      {countryInp?.current?.value?.toLowerCase() === "venezuela" ? bankNames.map((elem) => {
                        return <li
                          key={elem}
                          className="dropdown-item"
                          type="button"
                          onClick={(e) =>
                            setData((prev) => ({
                              ...prev,
                              bbank: elem,
                            }))
                          }
                        >
                          {elem}
                        </li>
                      }) :
                        relationships.map((elem) => {
                          return <li
                            key={elem}
                            className="dropdown-item"
                            type="button"
                            onClick={(e) =>
                              setData((prev) => ({
                                ...prev,
                                bbank: elem,
                              }))
                            }
                          >
                            {elem}
                          </li>
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>

              {countryInp?.current?.value?.toLowerCase() === "venezuela" &&
                <div className="d-flex mt-3  flex-wrap">
                  <div className="d-flex align-items-center col-lg-4 col-md-5 col-12">
                    <input
                      type="radio"
                      name="cuenta"
                      className="mb-0"
                      checked={cuenta}
                      onChange={(e) => {
                        setCuenta(!cuenta);
                        setData({ ...data, bAccnumber: "" });
                      }}
                    />
                    <label className="ms-2">Número de Cuenta</label>
                  </div>
                  <div className="d-flex ms-md-5 align-items-center">
                    <input
                      type="radio"
                      name="cuenta"
                      className="mb-0"
                      checked={!cuenta}
                      onChange={(e) => {
                        setCuenta(!cuenta);
                        setData({ ...data, bAccnumber: "" });
                      }}
                    />
                    <label className="ms-2">Pago Móvil</label>
                  </div>
                </div>
              }
              {countryInp?.current?.value?.toLowerCase() !== "venezuela" && <label className="mt-3">Teléfono Beneficiario</label>}
              <input
                className="form-control rounded mt-1"
                type="text"
                id="bAccnumber"
                value={data.bAccnumber}
                onChange={(e) => {
                  handleAccountNumber(e)
                }}
                placeholder=""
                required={true}
                min={countryInp?.current?.value?.toLowerCase() === "venezuela" ? (cuenta ? 20 : 11) : 0}
                max={countryInp?.current?.value?.toLowerCase() === "venezuela" ? (cuenta ? 20 : 11) : 100}
              />


              <div className="mt-3">
                <label className="">Cantidad a enviar (EUR)</label>
                <div className="d-flex align-items-center rounded w-100 w-resp-full">
                  <input
                    className="form-control me-2 w-50 w-resp-full"
                    type="text"
                    placeholder=""
                    required
                    ref={amountInp}
                    id="amount"
                    inputMode="numeric"
                    onInput={(e) => {
                      format(e)
                      handleChange(e);
                      Calculate();
                    }}
                    onChange={(e) => {
                      format(e)
                      handleChange(e);
                      Calculate();
                    }}
                  />
                  <p className="small text-danger">Máx. 3.000,00 €</p>
                </div>
                <p className="small text-danger"> Solo números enteros. No decimales.</p>
              </div>

              {/*  */}
              <div className="mt-3">
                <label className="">Forma de Pago:</label>
                <div className="d-flex mt-3  flex-wrap w-100">
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="payment"
                      className="mb-0"
                      checked={data.ptype === "Transfer"}
                      onChange={(e) => {
                        setData({ ...data, ptype: "Transfer" });
                        ptype.current = "Transfer";
                        Calculate()
                      }}
                    />
                    <label className="ms-2">Transferencia (0% Comisión)</label>
                  </div>
                  <div className="d-flex ms-md-3 align-items-center">
                    <input
                      type="radio"
                      name="payment"
                      className="mb-0"
                      checked={data.ptype === "Pago con Tarjeta"}
                      onChange={(e) => {
                        setData({ ...data, ptype: "Pago con Tarjeta" });
                        ptype.current = "Pago con Tarjeta";
                        Calculate()
                      }}
                    />
                    <label className="ms-2">Pago con Tarjeta (1,95% Gestión)</label>
                  </div>
                  <div className="d-flex ms-md-3 align-items-center">
                    <input
                      type="radio"
                      name="payment"
                      checked={data.ptype === "Paypal"}
                      className="mb-0"
                      onChange={(e) => {
                        setData({ ...data, ptype: "Paypal" });
                        ptype.current = "Paypal";
                        Calculate()
                      }}
                    />
                    <label className="ms-2">PayPal (3,50% Gestión)</label>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <label className="">Recibe*</label>
                <div className="d-flex rounded w-50 w-resp-full">
                  <input
                    type="text"
                    className="form-control bg-white"
                    id="gets"
                    ref={gets}
                    onChange={(e) => format(e)}
                    disabled={true}
                    required
                  />
                  <div className="curr text-uppercase" id="curr2" ref={curr2}></div>
                </div>
              </div>

              <p className="text-muted mt-1 px-2">
                Comisión:{" "}
                <span className="fw-bold" id="totalFees" ref={totalFees}></span>
              </p>
              <p className="text-muted px-2">
                Tasa: 1 EUR ={" "}
                <span
                  className="fw-bold"
                  id="exchangerate"
                  ref={exchangerate}
                ></span>
              </p>

              <div className="mt-3">
                <label>Total a pagar </label>
                <div
                  className="border d-flex mt-1 w-resp-full"
                  style={{ position: "relative", width: "50%" }}
                >
                  <input
                    type="text"
                    className="form-control bg-white"
                    id="total"
                    ref={total}
                    onChange={(e) => format(e)}
                    disabled={true}
                    required
                  />
                  <div className="curr">EUR</div>
                </div>
              </div>

              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="terms"
                  id="acceptTerms"
                  style={{ border: "1.99px solid black" }}
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                />
                <label className="form-check-label" htmlFor="acceptTerms">
                  Acepto los{" "}
                  <span
                    className="text-primary fw-bold"
                    type="button"
                    onClick={() => setShow2(true)}
                    data-bs-toggle="modal"
                    data-bs-target="#termsandcondition"
                  >
                    Términos y Condiciones
                  </span>{" "}
                  del servicio Transferven.
                </label>
              </div>

              <label className="mt-3 ">Observaciones especiales:</label>
              <textarea
                className="form-control rounded mt-1"
                required={false}
                id="remarks"
                onChange={handleChange}
                value={data.remarks}
              />

              <button
                className="continue mt-3 w-auto px-3"
                type="submit"
                disabled={loading}
              >
                {loading ? "Enviando" : "Enviar"}
              </button>

              <p className="mt-3 small text-danger">
                AVISO: Al hacer click en Enviar, aparecerán los datos para
                realizar el pago. Recuerda que esta solicitud no será procesada
                sin antes haber recibido tu pago. Te enviaremos un aviso al
                recibir los fondos y también cuando se haya completado la Orden.
              </p>
            </fieldset>
          </form>
        </div>


        {terms1 && (
          <div
            className="modal_bg d-flex justify-content-center 
          align-items-center"
          >
            <div
              className="modal"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog payment_dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Términos y Condiciones
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setTerms1(false);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <b>RECORDATORIO:</b> Los pagos deben realizarse desde una cuenta bancaria o tarjeta particular <b>A TU NOMBRE.</b> Los pagos realizados con terceros no serán aceptados, gastos por gestión serán aplicados y se devolverán a origen en un plazo de 15 días hábiles.
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn bg-p text-white"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setTerms2(true)
                        setTerms1(false);
                      }}
                    >
                      Entiendo
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ms-3"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setTerms1(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {terms2 && (
          <div
            className="modal_bg d-flex justify-content-center 
          align-items-center"
          >
            <div
              className="modal"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog payment_dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Términos y Condiciones
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setTerms2(false);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <b>RECORDATORIO:</b> Indicar en el concepto/observaciones/notas de tu pago, <b>ÚNICA Y EXCLUSIVAMENTE</b> el número de Orden indicado por el sistema. Pagos sin concepto u otras referencias generarán un atraso en el proceso de verificación de su pago y gastos por gestión.
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn bg-p text-white"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        Submit()
                      }}
                      disabled={loading}
                    >
                      {loading ? "Enviando" : "Entiendo"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ms-3"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setTerms2(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}




        {!modal && (
          <div
            className="modal_bg d-flex justify-content-center 
          align-items-center"
          >
            <div
              className="modal"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog payment_dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      ¡Aviso! Completa tus datos personales.
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setModal(true);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    Como empresa regulada, estamos obligados a verificar la Identidad de todos los usuarios en operaciones no presenciales, mediante el empleo de procedimientos seguros de identificación. Este proceso se realiza solo una vez.
                  </div>
                  <div className="modal-footer">
                    <p
                      className="continue w-auto fs-6 px-4 py-2 mt-0"
                      style={{ whiteSpace: "nowrap" }}
                      target="_blank"
                      type="button"
                      onClick={() => navigate(user ? `/profile?edit=true` : "/login")}
                    >
                      Completar
                    </p>
                    <button
                      type="button"
                      className="btn btn-secondary ms-3"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setModal(true);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {show && (
          <div
            className="modal_bg d-flex justify-content-center 
          align-items-center"
          >
            <div
              className="modal"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog payment_dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Detalles para el Pago
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShow(false);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <small className="text-danger mb-3">AVISO: Los pagos deben realizarse desde una cuenta/tarjeta bancaria A TU NOMBRE. <b>NO SE ACEPTARÁN PAGOS REALIZADOS CON CUENTAS DE TERCEROS.</b></small>
                    <div className="d-flex flex-wrap payment_modal_scroll">
                      {order.ptype !== "Paypal" && order.ptype !== "Pago con Tarjeta" && <><div className="me-4 mt-3">
                        <div className="d-flex align-items-center mb-2">
                          <div className="bg-danger pay_icon">
                            <AiFillBank size={25} color="white" />
                          </div>
                          <p className="ms-3 mb-0">Transferencia bancaria</p>
                        </div>
                        <p>TITULAR: EURO DIGITAL MULTISERVICES</p>
                        <p>BANCO: BBVA</p>
                        <p>IBAN: ES91 0182 0761 2002 0179 7309</p>
                        <p>
                          CONCEPTO:{" "}
                          <span className="fw-bold">"TV{order.orderId}"</span>
                        </p>
                        <p>
                          Factura:
                          <span className="fw-bold">
                            € {order.totalAmount?.replace("€", "")}{" "}
                          </span>
                        </p>
                      </div>
                        <div className="me-4 mt-3">
                          <div className="d-md-flex d-none align-items-center mb-2" style={{ visibility: "hidden", display: window.matchMedia("(max-width: 550px)").matches ? "none" : "flex" }}>
                            <div className="bg-danger pay_icon">
                              <AiFillBank size={25} color="white" />
                            </div>
                            <p className="ms-3 mb-0">Transferencia bancaria</p>
                          </div>
                          <p>TITULAR: EURO DIGITAL MULTISERVICES</p>
                          <p>BANCO: SANTANDER</p>
                          <p>IBAN: ES81 0049 0153 4128 1308 9268</p>
                          <p>
                            CONCEPTO:{" "}
                            <span className="fw-bold">"TV{order.orderId}"</span>
                          </p>
                          <p>
                            Factura:
                            <span className="fw-bold">
                              € {order.totalAmount?.replace("€", "")}{" "}
                            </span>
                          </p>
                        </div>
                      </>
                      }
                      {order.ptype === "Pago con Tarjeta" && <div className="me-4 mt-3">
                        <div className="d-flex align-items-center mb-2" >
                          <div className="bg-danger pay_icon">
                            <PiMoneyFill size={25} color="white" />
                          </div>
                          <p className="ms-3 mb-0">Tarjeta Débito / Crédito</p>
                        </div>
                        <p>TITULAR: EURO DIGITAL MULTISERVICES</p>
                        <p>Se aplica 1.95% comisión</p>
                        <p>
                          CONCEPTO:{" "}
                          <span className="fw-bold">"TV{order.orderId}"</span>
                        </p>
                        <p className="mb-2">
                          Factura:
                          <span className="fw-bold">
                            € {(order.totalAmount)}
                          </span>
                        </p>
                        <a href="https://wa.me/34620818181?text=Hola, solicito enlace de pago con Tarjeta para Orden N." target="_blank" className="continue px-3 py-1 d-inline">Solicitar Enlace</a>
                      </div>}
                      {order.ptype === "Paypal" && <div className="mt-3">
                        <div className="d-flex align-items-center mb-1">
                          <img src={paypal} height={60} />
                        </div>
                        <p>Se aplica 3.5% comisión</p>
                        <p>info.es@transferven.com</p>
                        <p>
                          CONCEPTO:{" "}
                          <span className="fw-bold">"TV{order.orderId}"</span>
                        </p>
                        <p>
                          Factura:{" "}
                          <span className="fw-bold">
                            € {(order.totalAmount)}
                          </span>
                        </p>
                      </div>
                      }
                    </div>
                    { order.ptype === "Pago con Tarjeta" ? <p className="mt-3 small text-danger">
                      INSTRUCCIONES: Haz click sobre el botón "Solicitar Enlace" para ser atendido por un Agente, el cual te proporcionará el enlace para tu pago con Tarjeta Bancaria.
                    </p>
                      :
                      <p className="mt-3 small text-danger">
                        IMPORTANTE: Evita conceptos tales como "Ayuda", "Manutención", "Donación". Colocar EXCLUSIVAMENTE el concepto que se indica en los detalles de tu pago para evitar atrasos.
                      </p>
                    }
                    <div ref={messageRef}></div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn bg-p text-white"
                      data-bs-dismiss="modal"
                      disabled={ploading}
                      onClick={() => {
                        HandlePaid();
                      }}
                    >
                      {ploading ? "Enviando" : "He pagado"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ms-3"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setShow(false);
                        toast.error(
                          "PAGA LUEGO ACCEDIENDO A HISTORIAL DE ORDENES", {
                          position: "top-center",
                          theme: "dark"
                        }
                        );
                      }}
                    >
                      Pagar después
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {show2 && (
          <div
            className="modal_bg d-flex justify-content-center 
            align-items-center"
          >
            <div
              className="modal"
              id="termsandconditions"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ height: "auto" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Términos y Condiciones
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShow2(false);
                      }}
                    ></button>
                  </div>
                  <div
                    className="modal-body"
                    style={{ height: "70vh", overflowY: "auto" }}
                  >
                    <div className="mt-2">
                      CONDICIONES GENERALES DEL SERVICIO DE ENVÍOS TRANSFERVEN
                      <br />
                      <br />
                      1. La empresa se reserva la facultad de excluir o no permitir el servicio, cuando considere que se infringe la normativa vigente, nuestras políticas de blanqueo, las condiciones generales o particulares, la moral, las costumbres generalmente aceptadas, el orden público, cuando se perjudique a un tercero, o cuando por razones derivadas de la propia imagen y reputación de la página web TRANSFERVEN no lo considere oportuno. En este caso, si el pago se hubiera realizado, se procederá a su devolución.
                      <br />
                      <br />
                      2. Una vez comprobado y recibido el pago, se ejecuta el envío de dinero, pudiendo el beneficiario verificarlo en destino. Todas las transacciones se ejecutan bajo un servidor seguro.
                      <br />
                      <br />
                      3. La empresa no se hace responsable por atrasos ocasionados por la interrupción del acceso a la web y/o retrasos o pérdidas de información causadas por interrupciones en los sistemas de comunicación que no están bajo el control de TRANSFERVEN (como cortes de electricidad, interrupción de líneas telefónicas o problemas causados por proveedor de internet del usuario o por un software específico instalado en el ordenador del usuario).
                      <br />
                      <br />
                      4. Si no queda satisfecho con los servicios, tiene derecho a desistir del servicio. El plazo legal de desistimiento es a los 14 días naturales. Sin embargo, por la propia naturaleza inmediata del servicio, no podemos atender este derecho una vez el mismo esté ejecutado, ya que se abona en la cuenta del tercero que usted haya señalado como beneficiario. Sin embargo, el cliente tiene derecho a cancelar una orden de envío de dinero pendiente, siempre que no se haya pagado en el país de destino de los fondos contactando con Atención al Cliente. TRANSFERVEN devolverá al cliente la cantidad total cobrada al cliente en el momento del procesamiento de la orden (incluyendo las tasas); siempre que la orden no se haya pagado al beneficiario.
                      <br />
                      <br />
                      5. Podrá solicitar y abonar un Envío 24 Hrs. y cualquier día del año desde nuestra Web o App Transferven. Estas solicitudes serán atendidas de lunes a viernes de 10 a 18 Hrs. y algunas inclusive de forma esporádica fines de semana y feriados. <br />
                      <br />
                      6. Al contratar los servicios de Transferven, usted, en calidad de cliente Remitente, acuerda, en su nombre o representación y en nombre del destinatario del envío y de cualquier persona interesada en el envío, que se aplicarán estos Términos y Condiciones generales, además de lo expuesto en <a href="www.transferven.com/terms" target="_blank"> www.transferven.com/terms</a>. <br />
                      <br />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn bg-p text-white"
                      data-bs-dismiss="modal"
                      disabled={ploading}
                      onClick={() => {
                        setTerms(true);
                        setShow2(false);
                      }}
                    >
                      Aceptar
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ms-3"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setShow2(false);
                        setTerms(false);
                      }}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

export default (Payment);
