import React from "react";
import { FaUser, FaHourglassEnd } from "react-icons/fa";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import one from "../assets/one.png";
import two from "../assets/two.png";
import three from "../assets/three.png";
import { Link } from "react-router-dom";

const HowWorks2 = () => {
  return (
    <div className="how_works2 w-50">
      <div className="">
        <h1 className="text-center fw-bold">¿Cómo Funciona?</h1>

        <div className="mt-4">
          <div className="text-center Card">
            <img src={one} width={60} />
            <h2 className="fs-4 fw-bold mt-2">Selecciona el Activo</h2>
            <p className="mt-2 font2 text-center">
            Elige la Criptomoneda y la cantidad que deseas adquirir.
            </p>
          </div>
          <div className="text-center Card mt-3">
            <img src={two} width={60} />
            <h2 className="fs-4 fw-bold mt-2">Registro y Verificación</h2>
            <p className="mt-2 font2 text-center">
            Abre tu Cuenta Transferven y confirma tu Identidad. 
            </p>
          </div>
          <div className="Card text-center mt-3">
            <img src={three} width={60} />
            <h2 className="fs-4 fw-bold mt-2">Recibe el Activo</h2>
            <p className="mt-2 font2 text-center">
            Los activos son enviados directamente a tu cartera de preferencia.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks2;
