import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { useState, useRef } from "react";
import ReactWhatsapp from "react-whatsapp";

const Chat = () => {
  return (
    <>
      <div className="dropdown dropup chat_drop w-100" id="chat">
        <div
          className="d-flex align-items-center justify-content-center
           chat_icon shadow"
          role="button"
          onClick={() => document.getElementById("whtsp").click()}
        >
          <BsWhatsapp
            size={30}
            color="#0dc143"
            // onClick={() => document.getElementById("whtsp").click()}
          />
          <ReactWhatsapp
            // number="+34 606 69 68 62"
            number="+34 620 81 81 81"
            style={{ pointerEvents: "none" }}
            id="whtsp"
            className="d-none btn btn-primary"
            // style={{opacity: 0}}
            message="¡Hola!"
          />
        </div>
      </div>
    </>
  );
};

export default Chat;
