import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import PageBanner from "../Components/PageBanner";
import { useAuthContext } from "../Contexts/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import whyImg from "../assets/whyIcon.png";
import KYC from "../assets/KYC TRANSFERVEN.png";
import loader from "../assets/loading.gif";


const Profile = ({ }) => {
  const location = useLocation();
  const { userdata, setuserData, user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [showModal, setModal] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [status, setStatus] = useState("started")

  const HandleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const editParam = params.get('edit');
    setModal(editParam === 'true');
  }, [location]);

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  let filterdata = Object.values(data).filter((elem) => elem.length > 0);

  const HandleEdit = async () => {
    if (Object.values(filterdata).length === 15 || Object.values(filterdata).length === 16) {
      // if (Object.values(filterdata).length === 17) {
      setLoading(true)
      const show = await axios.put(
        process.env.REACT_APP_BASE_URL + `/api/auth/update/${userdata._id}`,
        {
          newUser: {
            ...userdata,
            ...data
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );

      getUser();
      setModal(false);

      if (show.data.filter) {
        const { data } = await axios.post("https://neocheck.net/api/authorization/token", {
          username: "eurodigitalPRE",
          password: "45ut37RW"
        });

        const kyc_link = await axios.post(`https://neocheck.net/api/v1/VideoIdentifications/unattended/link?externalIdentifier=${userdata?.uid}`, {}, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`
          },
        })

        const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/auth/updateId`, {
          verificationId: kyc_link.data.split("/").pop()
        }, {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token")
          },
        }
        )

        navigate(`/verify?id=${kyc_link.data.split("/").pop()}`)
        setLoading(false)
      } else {
        toast.success(
          "VERIFICACIÓN EN CURSO", {
          position: "top-center",
          theme: "dark"
        }
        );
        setLoading(false)
      }
    } else {
      toast.error(
        "Completar todos los datos!", {
        position: "top-center",
        theme: "dark"
      }
      );
    }
  };

  const getUser = async () => {
    const res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/api/auth/user",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );
    setuserData(res.data);
  };

  useEffect(() => {
    getUser();
    setData(userdata);
    setData((prev) => ({ ...prev, residence: userdata.residence ? userdata.residence : "España" }));
  }, []);

  const countries = [
    "Alemania",
    "Bélgica",
    "Croacia",
    "Dinamarca",
    "España",
    "Francia",
    "Irlanda",
    "Letonia",
    "Luxemburgo",
    "Países Bajos",
    "Suecia",
    "Bulgaria",
    "Eslovaquia",
    "Estonia",
    "Grecia",
    "Malta",
    "Polonia",
    "República Checa",
    "Austria",
    "Chipre",
    "Eslovenia",
    "Finlandia",
    "Hungría",
    "Italia",
    "Lituania",
    "Portugal",
    "Rumanía",
  ];

  const occupations = [
    "Administrativo/a",
    "Agente de remesas",
    "Agricultura",
    "Albañilería",
    "Ama de Casa",
    "Asociaciones sin ánimo de lucro y religiosas",
    "Carpintería",
    "Comercial - Ventas",
    "Conductor/a - Chofer - Camionero/a",
    "Cuidado de personas / niños",
    "Dependiente/a - Cajero/a",
    "Desempleado/a - Sin actividad",
    "Directivo constructora / inmobiliaria",
    "Directivo/socio juegos de azar, casino y apuestas",
    "Directivo/socio sector joyería y oro",
    "Empleado/a de hogar",
    "Estudiante",
    "Fontanería",
    "Funcionario",
    "Hostelería (camarero/a)",
    "Limpieza",
    "Mecánico/a",
    "Militar",
    "Minería",
    "Notario",
    "Ocio Nocturno",
    "Operario/a de fábrica",
    "Peluquería",
    "Pensionista / Jubilado",
    "Pintor/a",
    "Propietario/a de bar/comercio",
    "Sector Armamentístico",
    "Sector Educación",
    "Sector industrial",
    "Sector sanitario",
    "Servicio Doméstico",
    "Trabajador de juegos de azar, casino y apuestas",
    "Trabajador sector joyería, oro y piedras preciosas",
    "Trabajador/a de la Construcción",
    "Transportista",
  ];

  const HandleDOBChange = (e) => {
    const input = e.target.value;
    const regex = /^[0-9-]*$/;

    if (regex.test(input) || input === '') {
      if (input.length <= 10) {
        setData((prev) => ({ ...prev, [e.target.id]: input }));
      }
    }

    // if (input.length <= 10) {
    //   // Add dashes automatically after the day and month
    //   if (input.length === 2) {
    //     setData((prev) => ({ ...prev, [e.target.id]: input + "-" }));
    //   }
    //   if (input.length === 5) {
    //     setData((prev) => ({ ...prev, [e.target.id]: input + "-" }));
    //   }
    // }
  };

  const DeleteAcc = async () => {
    const email = window.prompt("Indique su Email");
    const password = window.prompt("Introduzca su clave");

    try {
      const { data } = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/auth/delacc",
        {
          email,
          password
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );



      toast.success("CUENTA ELIMINADA", {
        position: "top-center",
        theme: "dark"
      }
      );
      Cookies.remove("token")
      navigate('/login')
    } catch (error) {
      console.log(error);
      toast.error((error?.response?.data?.message), {
        position: "top-center",
        theme: "dark"
      }
      );
    }
  }

  useEffect(() => {
    const Fetch = async () => {
      setLoading2(true)
      try {
        const { data } = await axios.post("https://neocheck.net/api/authorization/token", {
          username: "eurodigitalPRE",
          password: "45ut37RW"
        });

        const data2 = await axios.get(`https://neocheck.net/api/v1/VideoIdentifications/${userdata?.verificationId}/status`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.access_token}`
            },
          }
        )
        setStatus(data2.data)
        setLoading2(false)
      } catch (error) {
        setLoading2(false)
      }
    }
    if (userdata?.verificationId?.length > 0) {
      Fetch()
    } else {
      setLoading2(false)
    }
  }, [])


  // console.log(status)

  if (!user) return <Navigate to={"/"} />;
  // if (loading2) return <div style={{ height: "60vh" }}><img src={loader} alt="loading..." className="loader" /></div>;


  return (
    <div className="ProfileSection">
      <PageBanner title="Mi Cuenta" />
      <ToastContainer />
      <div className="px-4 profile_box mx-auto py-5 mt-10 bg-white rounded position-relative">
        <div className="d-flex align-items-center w-100 mt-3">
          <Link
            to={"/resetpassword"}
            style={{ position: "absolute", right: "5%", top: "3.5%" }}
            className="nav-link highlight"
          >
            Cambiar mi contraseña
          </Link>
          <div className="upload-btn-wrapper mt-4">
            <div className="avatar d-flex justify-content-center align-items-center">
              <FaUserAlt
                size={window.matchMedia("(max-width: 550px)").matches ? 40 : 70}
              />
            </div>
          </div>
          <div className="d-flex align-items-center ms-5 prof_info mt-4">
            <h1 className="mb-0">{userdata.name ? userdata.name + " " + userdata.surname : "Pendiente Verificación"}</h1>
          </div>
        </div>

        <div className="d-flex profile-flex">
          <div className="personal_det w-50 mt-5">
            <p className="fs-4 highlight fw-bold d-flex align-items-center">
              Datos Personales
              <MdModeEdit
                className="ms-2 text-muted fs-4"
                type="button"
                onClick={() => setModal(true)}
              />{" "}
              <button
                className="continue w-auto ms-2 px-2 py-1 mt-0"
                onClick={() => setModal(true)}
              >
                Actualiza
              </button>
            </p>
            <p className="mt-1 fs-5 font2">Email : {userdata.email}</p>
            <p className="mt-1 fs-5 font2">Teléfono: {userdata.phone}</p>
            <p className="mt-1 fs-5 font2">
              Fecha de Nacimiento: {userdata.dob}
            </p>
            <p className="mt-1 fs-5 font2">Tipo de Id : {userdata?.doctype}</p>
            <p className="mt-1 fs-5 font2">
              Número de Documento : {userdata?.docId}
            </p>
            <p className="mt-1 fs-5 font2">
              País de Residencia : {userdata.residence}
            </p>
            <p className="mt-1 fs-5 font2">
              Ocupación: {userdata.occupation}
            </p>
            <p className="mt-1 fs-5 font2">
              Código Postal : {userdata.postalcode}
            </p>
          </div>
          <div className="mt-5">
            <p className="mt-1 fs-5 font2">Código Cliente : {userdata.uid}</p>
          </div>
        </div>
        <p className="text-center mt-4 text-danger">
          Por favor mantenga su información personal actualizada en todo
          momento.{" "}
        </p>
        <p className="text-center mt-2 text-danger">
          ADVERTENCIA: El mal uso de tu Cuenta Transferven de forma reiterada, ocasionará la suspensión de la misma.
        </p>

        {showModal && (
          <div className="modal profile_modal_bg">
            <div className="modal-dialog mx-auto profile_modal">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Completa tus datos
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal(false)
                      navigate("/profile")
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <label>Nombres:</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="name"
                    onChange={(e) => HandleChange(e)}
                    value={data.name}
                    disabled={userdata?.name?.length > 0}
                  />
                  <p className="small text-danger">
                    Como aparece en tu Documento de Identidad.
                  </p>
                  <label className="mt-2">Apellidos:</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="surname"
                    onChange={(e) => HandleChange(e)}
                    value={data.surname}
                    disabled={userdata?.surname?.length > 0}
                  />
                  <p className="small text-danger">
                    Como aparece en tu Documento de Identidad.
                  </p>
                  <label className="mt-2">Fecha de Nacimiento:</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="dob"
                    placeholder="DD-MM-YYYY"
                    onChange={(e) => HandleDOBChange(e)}
                    value={data.dob}
                    autoComplete="off"
                  />
                  <label className="mt-2">Teléfono:</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="phone"
                    onChange={(e) => HandleChange(e)}
                    value={data.phone}
                  />


                  <label className="mt-2">
                    Tipo de Id:{" "}
                    <span className="small text-danger">
                      {" "}
                      Si es NIE sin fotografía, usar Pasaporte.
                    </span>
                  </label>

                  <div className="d-flex justify-content-between align-items-center col-md-8 col-12">
                    <div className="d-flex">
                      <input type='radio' name='doctype'
                        checked={data.doctype === "DNI"} onChange={() => setData({ ...data, doctype: "DNI" })} />
                      <label className='ms-2'>DNI</label>
                    </div>
                    <div className="d-flex">
                      <input type='radio'
                        checked={data.doctype === "NIE"} name='doctype' onChange={() => setData({ ...data, doctype: "NIE" })} />
                      <label className='ms-2'>NIE</label>
                    </div>
                    <div className="d-flex">
                      <input type='radio'
                        checked={data.doctype === "Pasaporte"} name='doctype' onChange={() => setData({ ...data, doctype: "Pasaporte" })} />
                      <label className='ms-2'>Pasaporte</label>
                    </div>
                  </div>

                  <label className="mt-2">Número de Documento:</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="docId"
                    onChange={(e) => HandleChange(e)}
                    value={data?.docId}
                    disabled={userdata?.docId?.length > 0}
                  />
                  <label className="mt-2">Dirección actual :</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="direction"
                    onChange={(e) => HandleChange(e)}
                    value={data?.direction}
                  />
                  <label className="mt-2">Número:</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="homenumber"
                    onChange={(e) => HandleChange(e)}
                    value={data?.homenumber}
                  />

                  <label className="mt-2">Pueblo, Ciudad, Provincia:</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="homevia"
                    onChange={(e) => HandleChange(e)}
                    value={data?.homevia}
                  />
                  <label className="mt-2">Código Postal:</label>
                  <input
                    type={"text"}
                    className="form-control rounded-0 mt-1"
                    id="postalcode"
                    onChange={(e) => HandleChange(e)}
                    value={data?.postalcode}
                  />

                  <label className="mt-2">País:</label>
                  <div className="dropdown">
                    <button
                      className="form-control field mt-1 rounded-0  text-start"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {data.residence ? data.residence : ""}
                    </button>
                    <ul
                      className="dropdown-menu w-100"
                      style={{ height: "200px", overflowY: "scroll" }}
                    >
                      {countries.sort().map((elem, i) => {
                        return (
                          <li
                            key={i}
                            className={"dropdown-item"}
                            type="button"
                            onClick={(e) =>
                              setData({ ...data, residence: elem })
                            }
                          >
                            {elem}
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <label className="mt-2">Ocupación:</label>
                  <div className="dropdown mt-1">
                    <button
                      className="form-control field rounded-0 mt-2 text-start"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {data.occupation ? data.occupation : "Ocupación"}
                    </button>
                    <ul
                      className="dropdown-menu w-100"
                      style={{ height: "200px", overflowY: "scroll" }}
                    >
                      {occupations.sort().map((elem, i) => {
                        return (
                          <li
                            key={i}
                            className={"dropdown-item"}
                            type="button"
                            onClick={(e) =>
                              setData({ ...data, occupation: elem })
                            }
                          >
                            {elem}
                          </li>
                        )
                      })}
                      <li
                        className={"dropdown-item"}
                        type="button"
                        onClick={(e) =>
                          setData({ ...data, occupation: "Otras Actividades" })
                        }
                      >
                        Otras Actividades
                      </li>
                    </ul>
                  </div>


                  {((status !== 4 && status !== 3 && status !== 0) && userdata.kyc === false) && <><p className="mt-3 fw-bold">Acredita tu Identidad</p>
                    <div className="d-flex align-items-start mb-3 mt-1">
                      <img src={whyImg} className="me-3" height={40} />
                      <p className="small"><span className="text-danger fw-bold">¿Por qué debo acreditar mi identidad?</span> Como Empresa regulada, es obligatorio verificar y registrar la identidad de todos nuestros usuarios en operativas financieras no presenciales. De esta manera, evitamos el fraude y cumplimos con las exigencias legales, normativas y regulaciones vigentes tales como AML, LGPD, eIDAS. Más info <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2010-6737" target="_blank" className="text-danger fw-bold">AQUÍ</a>.</p>
                    </div>

                    <img src={KYC} height={200} className="d-block mx-auto" />
                  </>
                  }
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary px-2 py-1"
                    data-bs-dismiss="modal"
                    onClick={() => setModal(false)}
                  >
                    Cerrar
                  </button>
                  {(status === 4 || status === 3 ||  status === "started") && <button
                    type="button"
                    className="continue w-auto ms-2 px-2 py-1 mt-0"
                    onClick={HandleEdit}
                  >
                    {loading ? "Enviando" : "Continuar"}
                  </button>
                  }
                  {(status !== 4 && status !== 3 && status !== "started") && <Link to={`/verify?id=${userdata.verificationId}`} className="ms-2 py-1 mt-0 px-2 continue" style={{ width: "fit-content" }}>Completar Verificación</Link>}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-5 profile_box mx-auto">
        <h1 className="text-danger fs-6">Eliminar tu Cuenta</h1>
        <small className="text-muted">Tu cuenta e información vinculada serán eliminadas permanentemente.</small>
        <br /><button className="btn btn-secondary mt-3"
          onClick={DeleteAcc}>Eliminar Cuenta</button>
      </div>
    </div >

  );
};

export default (Profile);