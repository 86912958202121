import React from "react";
import one from "../assets/one.png";
import two from "../assets/two.png";
import three from "../assets/three.png";

const HowWorks3 = () => {
    return (
        <div className="how_works2 w-50">
            <div className="">
                <h1 className="text-center fw-bold">¿Cómo Funciona?</h1>

                <div className="mt-4">
                    <div className="text-center Card">
                        <img src={one} width={60} />
                        <h2 className="fs-4 fw-bold mt-2">Selecciona el Operador</h2>
                        <p className="mt-2 font2 text-center">
                            Elige entre los diferentes Operadores en Venezuela.
                        </p>
                    </div>
                    <div className="text-center Card mt-3">
                        <img src={two} width={60} />
                        <h2 className="fs-4 fw-bold mt-2">Indica Número Telefónico</h2>
                        <p className="mt-2 font2 text-center">
                            Elige entre las diferentes cantidades disponibles.
                        </p>
                    </div>
                    <div className="Card text-center mt-3">
                        <img src={three} width={60} />
                        <h2 className="fs-4 fw-bold mt-2">Recibe tu Crédito</h2>
                        <p className="mt-2 font2 text-center">
                        En minutos y sin complicaciones.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowWorks3;
