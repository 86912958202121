import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineClockCircle, AiOutlineMenu } from "react-icons/ai";
import { MdCall, MdMailOutline, MdOutlineClose } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { useAuthContext } from "../Contexts/AuthContext";
import Cookies from "js-cookie";
import Logo from "../assets/Logo.svg";
import pin from "../assets/pin.svg"
import axios from "axios"

const Navbar = ({ color }) => {
  const { userdata, user, setUser, setuserData } = useAuthContext();
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const nav = useRef();

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY >= 30) {
        nav.current.style.top = 0;
      } else {
        nav.current.style.top =
          user && userdata && Object.values(userdata).length < 12
            ? "70px"
            : "0px";
      }
    };
  }, [nav.current?.offsetTop]);

  useEffect(() => {
    const getUser = async () => {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/auth/user",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      setuserData(res.data);
    }
    getUser()
  }, [userdata])

  return (
    <nav
      className={`Navbar bg-white text-black`}
      id="nav"
      ref={nav}
      style={{
        top:
          user && userdata && Object.values(userdata).length < 12
            ? "70px"
            : "0px",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center 
      canvas h-100"
      >
        <Link to={"/"} className="me-auto">
          {" "}
          {/* <img src={logo} alt="LatinoTransfer" className="logo"  */}
          {/* height={34} width={200} */}
          {/* /> */}
          <img src={Logo} height={37} className="NavLogo" />
        </Link>
        {window.matchMedia("(max-width: 650px)").matches && (
          <>
            {user ? (
              <div className="dropdown user_box">
                {/* <div
                  className="d-flex align-items-center justify-content-center 
                ms-3 nav-link user_avatar"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FaUserAlt size={25} color="white" />
                </div> */}
                <button type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn bg-p ms-3 text-white px-3">CUENTA</button>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to={"/profile"}>
                     Mis Datos
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={"/prealertar"}>
                      Hacer Envío
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={"/orderhistory"}>
                      Historial de Órdenes
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={"/beneficiarios"}>
                      Beneficiarios
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={"/recargas"}>
                      Recargas Teléfono
                    </Link>
                  </li>
                  <li>
                    <p
                      onClick={() => {
                        Cookies.remove("token");
                        setUser(false);
                        navigate("/");
                      }}
                      type="button"
                      className="dropdown-item"
                    >
                      Salir
                    </p>
                  </li>
                </ul>
              </div>
            ) : (
              <div>
                <Link to="/login" className="continue py-1 px-3 mt-0 w-auto">
                  ENTRAR
                </Link>
              </div>
            )}

            <AiOutlineMenu
              className="menu_icon ms-2"
              size={30}
              onClick={() => setMenu(!menu)}
              cursor={"pointer"}
            />
          </>
        )}
        {((window.matchMedia("(max-width: 650px)").matches && menu) ||
          window.matchMedia("(min-width: 650px)").matches) && (
            <div className="d-flex align-items-center menu">
              {window.matchMedia("(max-width: 650px)").matches && (
                <>
                  <MdOutlineClose
                    style={{
                      position: "absolute",
                      right: "2px",
                      top: "5%",
                    }}
                    onClick={() => setMenu(false)}
                    color="white"
                    cursor={"pointer"}
                    size={30}
                  />
                  <img src={pin} height={70} className="mb-3" />
                </>
              )}

              <Link to="/" className="nav-link">
                Inicio
              </Link>
              {!user ? (
                <>
                  <Link to="/registro" className="ms-3 nav-link">
                    Abrir Cuenta
                  </Link>
                </>
              ) : <Link to="/prealertar" className="ms-3 nav-link">
                Enviar Dinero
              </Link>}
              <Link to="/recargas" className="ms-3 nav-link">
                Recargas Teléfono
              </Link>
              {/* <Link to="/crypto" className="ms-3 nav-link">
                Criptomonedas
              </Link> */}
              <Link to="/contact" className="ms-3 nav-link">
                Contacto
              </Link>

              {window.matchMedia("(max-width: 650px)").matches && (
                <>
                  <div className="mt-3">
                    <MdMailOutline size={20} color="#fc364e" className="me-2" />
                    <Link to={"/contact"} className="text-white ms-3" style={{textDecoration: 'none'}}>
                      info.es@transferven.com
                    </Link>
                  </div>
                  <div className="mt-2 d-flex align-items-center">
                    <AiOutlineClockCircle size={20} color="#fc364e" />
                    <p className="text-white ms-3">L a V de 10 a 18 Hrs.</p>
                  </div>
                  <div className="mt-2 d-flex align-items-center">
                    <MdCall size={20} color="#fc364e" />
                    <p className="text-white ms-3">+34 91 993 13 12</p>
                  </div>
                  <div className="mt-2 d-flex align-items-center">
                  <FaWhatsapp size={20} color="#fc364e" />
                    <p className="text-white ms-3">+34 620 81 81 81</p>
                  </div>
                </>)
              }


              {!user && window.matchMedia("(min-width: 650px").matches && (
                <Link
                  to="/login"
                  className="continue py-1 px-3 mt-0 ms-3 nav-link"
                >
                  ENTRAR
                </Link>
              )}

              {user && window.matchMedia("(min-width: 650px)").matches && (
                <>
                  <div className="dropdown user_box">
                    <button type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="btn bg-p ms-3 text-white px-3">CUENTA</button>

                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to={"/profile"}>
                         Mis Datos
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to={"/prealertar"}>
                          Hacer Envío
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to={"/orderhistory"}>
                          Historial de Órdenes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to={"/beneficiarios"}>
                          Beneficiarios
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to={"/recargas"}>
                          Recargas Teléfono
                        </Link>
                      </li>

                      <li>
                        <p
                          onClick={() => {
                            Cookies.remove("token");
                            setUser(false);
                            navigate("/");
                          }}
                          type="button"
                          className="dropdown-item"
                        >
                          Salir
                        </p>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          )}
      </div>
    </nav>
  );
};

export default Navbar;
