import { createContext, useContext, useRef, useState } from "react";

const CalcContext = createContext();

export default function CalcContextProvider({ children }) {
  const [inputValues, setInputValues] = useState({total: "",})

  const calc = useRef(inputValues);
  const [mode, setMode] = useState("Aéreo");
  const [packages, setPackages] = useState([0]);

  
  return (
    <CalcContext.Provider value={{ calc, mode, setMode, packages, setPackages, setInputValues }}>
      {children}
    </CalcContext.Provider>
  );
}

export function useCalcContext() {
  return useContext(CalcContext);
}
