import React, { useEffect } from "react";
import PageBanner from "../Components/PageBanner";
import Calculator from "../Components/Calculator";
import { AiFillWarning } from "react-icons/ai";
import { useAuthContext } from "../Contexts/AuthContext";
import { Navigate } from "react-router-dom";

const Recogida = () => {
  const {user} = useAuthContext();

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  if(!user) return <Navigate to={"/login"} />;

  return (
    <div className="OrderSection">
      <PageBanner title="Solicitar Recogida" />


      <div className="px-3 profile_box mx-auto py-5 mt-10 bg-white rounded position-relative">
      <div className="d-flex calculator pt-0">
            <AiFillWarning color="red" size={45} className="me-2"/>
            <p>
            Instrucciones: Envíanos los datos de tu paquete para recogerlo directamente en tu domicilio. El plazo para recibir esta cotización es de 5 min a 24 Hrs. laborables y podrás aceptar o rechazar la cotización en el mismo email recibido.
            </p>
          </div>
        <Calculator advanced={true} home={true} 
        calculate={true} btn={true} 
        small={true}
        />
      <p className="mt-3 small text-danger calculator pt-0">ATENCIÓN: Todos los paquetes son inspeccionados a través de Rayos X. Asegúrate de haber declarado todo el contenido de tu envío, especialmente los artículos electrónicos. El cliente será responsable de cualquier sanción Aduanal.</p>
      </div>
    </div>
  );
};

export default Recogida;
