import React, { useEffect, useState } from "react";
import FAQS3 from "../Components/FAQS3";
import PageBanner from "../Components/PageBanner";
import HowWorks3 from "../Components/HowWorks3";
import img from "../assets/rechargeheader.jpg"
import Calculator3 from "../Components/Calculator3";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../Contexts/AuthContext";

const Home3 = () => {
  const navigate = useNavigate()
  const { userdata, user } = useAuthContext()
  const [modal, setModal] = useState(user ? userdata.kyc : true);

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  
  return (
    <div className="home2">
      <PageBanner title="Recargas" img={img} />
      <div className={`d-flex align-items-start mt-10 canvas 
     rounded p-md-3 justify-content-between ${window.matchMedia("(max-width: 850px)").matches && "flex-column"}`}>
        <HowWorks3 />
        <Calculator3 />
      </div>
      <FAQS3 />

<div className="OrderSection">
      {!modal && (
        <div
          className="modal_bg d-flex justify-content-center 
          align-items-center"
        >
          <div
            className="modal"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog payment_dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    ¡Aviso! Completa tus datos personales.
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal(true);
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  Como empresa regulada, estamos obligados a verificar la Identidad de todos los usuarios en operaciones no presenciales, mediante el empleo de procedimientos seguros de identificación. Este proceso se realiza solo una vez.
                </div>
                <div className="modal-footer">
                  <p
                    className="continue w-auto fs-6 px-4 py-2 mt-0"
                    style={{ whiteSpace: "nowrap" }}
                    target="_blank"
                    type="button"
                    onClick={() => navigate(user ? `/profile?edit=true` : "/login")}
                  >
                    Completar
                  </p>
                  <button
                    type="button"
                    className="btn btn-secondary ms-3"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default Home3;
