import React, { useEffect } from "react";
import Calculator from "../Components/Calculator";
import PageBanner from "../Components/PageBanner";
import { useAuthContext } from "../Contexts/AuthContext";
import { Navigate } from "react-router-dom";

const Calcular = () => {
  const {user} = useAuthContext()

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  if(!user) return <Navigate to={"/"} />;
  
  return (
    <div className="OrderSection">
      <PageBanner title="Calcular Envío" />
      <div className="px-5 profile_box mx-auto py-5 mt-10 bg-white rounded position-relative">
        <Calculator btn={false} />
      </div>
    </div>
  );
};

export default Calcular;
