import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import { HiMail } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { MdCall } from "react-icons/md";

import axios from "axios";
import { Link } from "react-router-dom";
import contacto from "../assets/contact.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
const Contact = ({ }) => {
  const [cred, setCred] = useState({
    email: "",
    phone: "",
    message: ""
  });
  const [robot, setRobot] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  function onChange(value) {
    setRobot(value);
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (
      cred.email.length > 0 &&
      cred.phone.length > 0 &&
      cred.message.length > 0 &&
      robot
    ) {
      setLoading(true);
      if (document.getElementById("flexCheckDefault").checked) {
        await axios.post(process.env.REACT_APP_BASE_URL + "/api/auth/contact", {
          ...cred,
        });
        toast.success("¡MENSAJE ENVIADO!",
          {
            position: "top-center",
            theme: "dark"
          }
        );
        document.getElementById("flexCheckDefault").checked = false;
        setCred({ phone: "", email: "", message: "" });
        setLoading(false);
      } else {
        toast.error("Acepta nuestros Términos y Condiciones",
          {
            position: "top-center",
            theme: "dark"
          }
        );
        setLoading(false);
      }
    } else {
      toast.error("Completar todos los datos!",
        {
          position: "top-center",
          theme: "dark"
        })
    }
  };

  const HandleChange = (e) => {
    setCred((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  return (
    <div className="contact bg-white column-resp">
      <PageBanner title={"Contacto"} img={contacto} />
      <ToastContainer />
      <div className="canvas col_resp pb-4">
        <h1 className="fw-bold mt-5 p-3 text-center">Atención al Cliente</h1>
        <form
          className="d-flex justify-content-between mt-4 col_resp"
          onSubmit={HandleSubmit}
        >
          <div className="register_1">
            <div className="d-flex w-100">
              <div className="d-flex align-items-center justify-content-center icon me-3">
                <HiMail size={40} color="white" />
              </div>
              <div>
                <h3 className="fw-bold">Email</h3>
                <p className="font2 mt-2">info.es@transferven.com</p>
              </div>
            </div>
            <div className="d-flex w-100 mt-4">
              <div
                className="d-flex align-items-center justify-content-center
           icon me-3"
              >
                <FiPhoneCall size={37} color="white" />
              </div>
              <div>
                <h3 className="fw-bold">Call Center</h3>
                <div className="mt-2 d-flex align-items-center">
                  <MdCall size={20} color="#fc364e" />
                  <p className="font2 ms-3">+34 91 993 13 12</p>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <FaWhatsapp size={20} color="#fc364e" />
                  <p className="ms-3 font2">+34 620 81 81 81</p>
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <AiOutlineClockCircle size={20} color="#fc364e" />
                  <p className="ms-3 font2">L a V de 10 a 18 Hrs.</p>
                </div>

              </div>
            </div>
          </div>
          <div className="register_2">
            <label>Nombre</label>
            <input
              type={"text"}
              className="rounded-0 form-control mt-2"
              onChange={(e) => HandleChange(e)}
              id="phone"
              value={cred.phone}
            />
            <label className="mt-1">Email</label>
            <input
              type={"email"}
              className="rounded-0 form-control mt-1"
              onChange={(e) => HandleChange(e)}
              id="email"
              value={cred.email}
            />
            <label className="mt-2">Mensaje</label>
            <textarea
              className="form-control mt-1 rounded-0"
              placeholder="Escribe tu mensaje"
              onChange={(e) => HandleChange(e)}
              id="message"
              value={cred.message}
            />

            <div className="d-flex align-items-start form-check mt-3">
              <input
                className="form-check-input mb-0"
                style={{ width: "25px", height: "25px" }}
                type="checkbox"
                id="flexCheckDefault"
              />
              <label
                className="form-check-label text-muted ms-2"
                htmlFor="flexCheckDefault"
                style={{ width: "calc(100% - 35px)" }}
              >
                Haciendo click aquí, aceptas nuestros{" "}
                <Link to="/terms" target="_blank">
                  Términos y Condiciones
                </Link>{" "}
                y
                <Link to={"/protection"} target="_blank">
                  {" "}
                  Política general en materia de Protección de Datos
                </Link>{" "}
                de Transferven y nos autorizas a contactarte por E-mail, SMS y
                Whatsapp.
              </label>
            </div>

            <ReCAPTCHA
              sitekey="6Ld2YggpAAAAACwfxHxBPm1_LxtxDO17jtULGO3T"
              onChange={onChange}
              onExpired={(e) => e.reset()}
              className="mt-3"
            />

            <p className="text-danger">{!robot && "Verify Recaptcha!"}</p>

            <button
              className="continue w-auto px-4"
              disabled={loading}
            >
              {loading ? "Enviando" : "Enviar"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default (Contact);
