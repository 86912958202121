import React, { useEffect } from "react";
import FAQS2 from "../Components/FAQS2";
import PageBanner from "../Components/PageBanner";
import HowWorks2 from "../Components/HowWorks2";
import Calculator from "../Components/Calculator";
import img from "../assets/crypto.jpg";
import Calculator2 from "../Components/Calculator2";

const Home2 = () => {
  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  return (
    <div className="home2">
      <PageBanner title="Criptomonedas" img={img} />
      <div className={`d-flex align-items-start mt-10 canvas 
     rounded p-3 justify-content-between ${window.matchMedia("(max-width: 850px)").matches && "flex-column"}`}>
        <HowWorks2 />
        <Calculator2 />
      </div>
      <FAQS2 />
      <div className="mt-5"></div>
      <div className="canvas bg-white shadow rounded p-3">
        <div className="col-md-10 col-12 mx-auto text-center">
      La inversión en criptoactivos no está regulada, puede no ser adecuada para inversores minoristas y perderse la totalidad del importe invertido.
Es importante leer y comprender los riesgos de esta inversión que se explican detalladamente en esta <a href="./Advertencia de riesgos de Criptoactivos TRANSFERVEN.pdf" target="_blank">ubicación</a>. 
        </div>
      </div>
    </div>
  );
};

export default Home2;
