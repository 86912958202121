import React, { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify"
import Cookies from "js-cookie";


const Calculator2 = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [selected, setSelected] = useState("");
    const [direccion, setDir] = useState("");
    const [red, setRed] = useState("Tron (TRC20)");
    const [data, setData] = useState([]);
    const flag = useRef("")
    const sign = useRef("")
    const exch = useRef("")
    const fees = useRef("")
    const amountInp = useRef(null);
    const gets = useRef(null);
    const total = useRef(null);
    const { userdata } = useAuthContext()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState("")
    const [terms1, setTerms1] = useState(false);
    const [terms2, setTerms2] = useState(false);
    const [modal, setModal] = useState(userdata?.length > 0 ? userdata.kyc: true);



    const Continue = async () => {
        if (user) {
            if (red.length > 0 && selected.length > 0 && total.current.value?.length > 0 && direccion.length > 0) {
                if (total.current.value.replaceAll(",", "") >= 100) {
                    try {
                        setLoading(true)
                        await axios.post(process.env.REACT_APP_BASE_URL + "/api/orders/cripto",
                            {
                                total: total.current.value,
                                gets: gets.current.value,
                                amount: amountInp.current.value,
                                email: userdata.email,
                                userId: userdata.uid,
                                direction: direccion,
                                red: red,
                                name: selected
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    token: Cookies.get("token"),
                                },
                            })

                        setLoading(false)
                        total.current.value = ""
                        gets.current.value = ""
                        amountInp.current.value = ""
                        setShow("")
                        setRed("")
                        setDir("")
                        setTerms2(false)
                        setTerms1(false)
                        toast.success("Orden Recibida", {
                            position: "top-center",
                            theme: "dark"
                        });
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    toast.error("Mínimo 100,00 EUR", {
                        position: "top-center",
                        theme: "dark"
                    });
                }
            } else {
                toast.error("Completar todos los datos!", {
                    position: "top-center",
                    theme: "dark"
                });
            }
        } else {
            navigate("/login")
        }
    }

    useEffect(() => {
        const Fetch = async () => {
            const { data } = await axios.get(
                process.env.REACT_APP_BASE_URL + "/api/rates2/getrates"
            );

            flag.current = (data.data[0].FLAG)
            setSelected(data.data[0].CRIPTO)
            sign.current = (data.data[0].NOM)
            exch.current = (data.data[0].EXCHRATE)
            fees.current = (data.data[0].FEE)

            setData(data.data)
        };
        Fetch();
    }, []);


    function Calculate() {
        let get = (
            amountInp.current.value.replaceAll(".", "").replaceAll(",", ".") /
            exch.current.replaceAll(",", "cc").replaceAll(".", "dd")
                .replaceAll("cc", ".").replaceAll("dd", "")
        ).toLocaleString("en-En", {
            minimumFractionDigits: 6,
            maximumFractionDigits: 6,
        });
        gets.current.value = get.replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",")


        let totals = (
            Number(amountInp.current.value.replaceAll(".", "").replace(",", ".")) +
            Number(fees.current.replaceAll("€", "")
                .replaceAll(",", "cc").replaceAll(".", "dd")
                .replaceAll("cc", ".").replaceAll("dd", ""))
        ).toLocaleString("en-En", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        total.current.value = totals;

        //   total.current.value = totals.replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");
    }


    const blockchains = [
        "Tron (TRC20)",
        "BNB Smart Chain (BEP20)",
        "Ethereum (ERC20)",
        "Polygon",
        "Toncoin",
        "Arbitrum One",
        "AVAX C-Chain",
        "Solana",
        "Optimism",
        "NEAR Protocol",
        "opBNB",
        "CELO",
        "KAVAEVM",
        "EOS",
        "Asset Hub (Polkadot)",
        "Tezos"
    ];

    return (
        <div className="rounded border calculator mt-3 OrderSection">
            <div className="mt-3 px-2">
                <label>CriptoMoneda</label>
                <div
                    className="d-flex mt-1 bb align-items-center"
                    style={{ position: "relative", width: "100%" }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <img style={{ height: 30, padding: "0px 10px" }} alt={`Latinotransfer `}
                        src={flag.current} />
                    <input
                        type="text"
                        className="form-control rounded-0"
                        autoComplete="off"
                        value={selected}
                        readOnly
                        disabled={user ? !userdata.kyc : false}
                    />
                    <div className="dropdown" style={{ position: "static" }}>
                        <button
                            className="btn border dropdown-toggle rounded-0 h-100"
                            type="button"
                        ></button>
                        <ul className="dropdown-menu" id="country">
                            {data?.map((elem) => {
                                return <li
                                    type="button"
                                    className="py-1 px-2"
                                    key={elem.NOM}
                        disabled={user ? !userdata.kyc : false}
                                    onClick={() => {
                                        sign.current = (elem.NOM)
                                        setSelected(elem.CRIPTO)
                                        flag.current = (elem.FLAG)
                                        exch.current = (elem.EXCHRATE)
                                        fees.current = (elem.FEE)
                                        Calculate();
                                    }}
                                >
                                    {elem.CRIPTO}
                                </li>
                            })
                            }
                        </ul>
                    </div>
                </div>
            </div>

            <div className="mt-3 px-2">
                <label>Cantidad</label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control"
                        id="amountInp"
                        inputMode="numeric"
                        ref={amountInp}
                        onInput={(e) => {
                            Calculate()
                            setShow(e.target.value)
                        }}
                                                disabled={user ? !userdata.kyc : false}

                    />
                    <div className="curr">EUR</div>
                </div>
            </div>

            <div className="mt-3 px-2">
                <label>Recibe*</label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control bg-white"
                        id="gets"
                        disabled={true}
                        ref={gets}
                    />
                    <div className="curr" id="curr2">{sign.current}</div>
                </div>
            </div>
            <p className="text-muted mt-1 px-2">
                Comisión:{" "}
                <span className="fw-bold" id="totalFees">{fees.current}</span>
            </p>
            <p className="text-muted px-2">
                Tasa: 1 EUR = {" "}
                <span
                    className="fw-bold"
                    id="exchangerate"
                >{exch.current}</span>
            </p>

            <div className="mt-3 px-2">
                <label>Total a pagar </label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control bg-white"
                        id="total"
                        disabled={true}
                        ref={total}
                    />
                    <div className="curr">EUR</div>
                </div>
            </div>
            <p className="text-muted px-2 small">
                *Resultado sujeto a tasas y tipos de cambio variables.
            </p>

            {show?.length > 0 && <><div className="mt-3 px-2">
                <label>Dirección</label>
                <div
                    className="bb d-flex mt-1"
                    style={{ position: "relative", width: "100%" }}
                >
                    <input
                        type="text"
                        className="form-control bg-white"
                        id="direccion"
                        onChange={(e) => setDir(e.target.value)}
                    />
                </div>
            </div>
                <div className="mt-3 px-2">
                    <div className="flex">
                        <label className="flex me-2">Red</label>
                        <button type="button" className="btn btn-danger p-0 rounded-circle" style={{ width: "23px", height: "23px" }} data-toggle="tooltip" data-placement="top" title="El tiempo de procesamiento, comisión y cantidades mínimas podrían variar según la red seleccionada.">
                            i
                        </button>
                    </div>
                    <div
                        className="bb d-flex mt-1"
                        style={{ position: "relative", width: "100%" }}
                    >
                        <select
                            type="text"
                            className="form-control bg-white"
                            id="red"
                                                disabled={user ? !userdata.kyc : false}

                            onChange={(e) => setRed(e.target.value)}
                        >
                            {
                                blockchains.map((elem) => {
                                    return <option value={elem} key={elem}>{elem}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </>
            }
            <button onClick={() => {
                if (user) {
                    if (red.length > 0 && selected.length > 0 && total.current.value?.length > 0 && direccion.length > 0) {
                        if (total.current.value.replaceAll(",", "") >= 100) {
                            setTerms1(true)
                        } else {
                            toast.error("Mínimo 100,00 EUR", {
                                position: "top-center",
                                theme: "dark"
                            });
                        }
                    } else {
                        toast.error("Completar todos los datos!", {
                            position: "top-center",
                            theme: "dark"
                        });
                    }
                } else {
                    navigate("/login")
                }

            }} className="continue" disabled={loading || (user ? !userdata.kyc : false)}>
                {loading ? "Enviando" : "Continuar"}
            </button>




            {!modal && (
                <div
                    className="modal_bg d-flex justify-content-center 
          align-items-center"
                >
                    <div
                        className="modal"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog payment_dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                                        ¡Aviso! Completa tus datos personales.
                                    </h1>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => {
                                            setModal(true);
                                        }}
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    Como empresa regulada, estamos obligados a verificar la Identidad de todos los usuarios en operaciones no presenciales, mediante el empleo de procedimientos seguros de identificación. Este proceso se realiza solo una vez.
                                </div>
                                <div className="modal-footer">
                                    <p
                                        className="continue w-auto fs-6 px-4 py-2 mt-0"
                                        style={{ whiteSpace: "nowrap" }}
                                        target="_blank"
                                        type="button"
                                        onClick={() => navigate(user ? `/profile?edit=true` : "/login")}
                                    >
                                        Completar
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-secondary ms-3"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setModal(true);
                                        }}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {terms1 && (
                <div
                    className="modal show  d-flex justify-content-center 
          align-items-center"
                    style={{ height: "100vh", background: "rgba(0,0,0,0.3)" }}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog payment_dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">
                                    Términos y Condiciones
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setTerms1(false);
                                    }}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <b>RECORDATORIO:</b> Los pagos deben realizarse desde una cuenta bancaria o tarjeta particular <b>A TU NOMBRE.</b> Los pagos realizados con terceros no serán aceptados, gastos por gestión serán aplicados y se devolverán a origen en un plazo de 15 días hábiles.
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn bg-p text-white"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setTerms2(true)
                                        setTerms1(false);
                                    }}
                                >
                                    Entiendo
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary ms-3"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setTerms1(false);
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {terms2 && (
                <div
                    className="modal show  d-flex justify-content-center 
      align-items-center"
                    style={{ height: "100vh", background: "rgba(0,0,0,0.3)" }}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog payment_dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">
                                    Términos y Condiciones
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setTerms2(false);
                                    }}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <b>RECORDATORIO:</b> Indicar en el concepto/observaciones/notas de tu pago, <b>ÚNICA Y EXCLUSIVAMENTE</b> el número de Orden indicado por el sistema. Pagos sin concepto u otras referencias generarán un atraso en el proceso de verificación de su pago y gastos por gestión.
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn bg-p text-white"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        Continue()
                                    }}
                                    disabled={loading}
                                >
                                    {loading ? "Enviando" : "Entiendo"}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary ms-3"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        setTerms2(false);
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Calculator2;
