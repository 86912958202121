import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import loader from "../assets/loading.gif";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState(
    Cookies.get("token") && jwt_decode(Cookies.get("token"))
  );
  const [userdata, setuserData] = useState({});
  const [loading, setLoading] = useState(Cookies.get("token") ? true : false);
  const [cookies, setCookies] = useState(
    Cookies.get("cookies") ? JSON.parse(Cookies.get("cookies")) : false
  );

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const { data } = await axios.post(
          process.env.REACT_APP_BASE_URL + "/api/auth/user",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );

        setuserData(data);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    Cookies.get("token") && fetch();
  }, [user]);

  if (loading) return <img src={loader} alt="loading..." className="loader" />;

  return (
    <AuthContext.Provider
      value={{
        verified,
        setVerified,
        user,
        setUser,
        userdata,
        setuserData,
        cookies,
        setCookies,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
