import React, { useEffect, useRef, useState } from "react";
import { useCalcContext } from "../Contexts/CalcContext";
import { useAuthContext } from "../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";


const Calculator = ({ btn }) => {
  const { user } = useAuthContext();
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const { setCalc } = useCalcContext();
  const country = useRef();
  const [filterData, setFilter] = useState([]);
  const countryInp = useRef();
  const [first, setFirst] = useState(true);
  const curr2 = useRef();
  const totalFees = useRef();
  const exchangerate = useRef();
  const flag = useRef();
  const gets = useRef();
  const amountInp = useRef();
  const total = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    const Fetch = async () => {
      const { data } = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/rates/getrates"
      );

      curr2.current.innerHTML = data.data[0].NOM;
      countryInp.current.value = data.data[0].COUNTRY;
      exchangerate.current.innerHTML = data.data[0].EXCHRATE  + " " + data.data[0].NOM;
      totalFees.current.innerHTML = data.data[0].FEE;
      flag.current.style.display = "flex";
      flag.current.src = data.data[0].FLAG;

      setData(data?.data?.sort((a, b) => a.COUNTRY.localeCompare(b.COUNTRY)));
    };
    Fetch();
  }, []);

  function showList(e) {
    var filtered = data.filter((elem) => {
      return elem.COUNTRY.toLowerCase().startsWith(
        e.target.value.toLowerCase()
      );
    });

    setFilter(filtered);
  }

  let countryData = [];

  const setCountry = (country, nom) => {
    curr2.current.innerHTML = nom;
    countryInp.current.value = country;

    countryData = data.filter((elem) => {
      return elem.NOM === nom && elem.COUNTRY === country;
    });

    totalFees.current.innerHTML = countryData[0].FEE;
    exchangerate.current.innerHTML = countryData[0].EXCHRATE + " " + nom;
    flag.current.style.display = "flex";
    flag.current.src = countryData[0].FLAG;
    Calculate();
  };

  function Calculate() {
    countryData = data.filter((elem) => {
      return (
        elem.NOM === curr2.current.innerHTML &&
        elem.COUNTRY === countryInp.current.value
      );
    });

    if (countryData.length > 0) {
      let get = (
        amountInp.current.value.replaceAll(".", "").replaceAll(",", ".") *
        countryData[0].EXCHRATE.replaceAll(",", "cc").replaceAll(".", "dd")
          .replaceAll("cc", ".").replaceAll("dd", "")
      ).toLocaleString("en-En", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      gets.current.value = get.replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",")


      let totals = (
        Number(amountInp.current.value.replaceAll(".", "").replace(",", ".")) +
        Number(totalFees.current.innerHTML.replaceAll("€", "")
          .replaceAll(",", "cc").replaceAll(".", "dd")
          .replaceAll("cc", ".").replaceAll("dd", ""))
      ).toLocaleString("en-En", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      total.current.value = totals.replaceAll(".", "dd").replaceAll(",", "cc").replaceAll("cc", ".").replaceAll("dd", ",");

      // setCalc({
      //   destination: countryInp?.current.value,
      //   amount: amountInp?.current.value,
      //   recibe: gets?.current.value,
      //   total: total?.current.value,
      // });
    }
  }

  const CheckStatus = async () => {
    try {
      const { data } = await axios.put(
        process.env.REACT_APP_BASE_URL + "/api/orders/checkstatus",
        {
          orderId: id.replace("TV", "").replace("tv", ""),
        }
      );
      setStatus(data?.status);
    } catch (err) {
      toast.error("Número de Orden no existe", {
        position: "top-center",
        theme: "dark"
      });
      console.log(err);
    }
  };

  const CheckColor = (elem) => {
    let color = "";
    if (
      elem.toUpperCase() === "PENDIENTE" ||
      elem.toUpperCase() === "ANULADA"
    ) {
      color = "red";
    } else if (elem.toUpperCase() === "COMPLETADA") {
      color = "green";
    } else {
      color = "blue";
    }

    return color;
  };

  const Continue = () => {
    if (
      countryInp.current.value.length > 0 &&
      totalFees.current.innerHTML &&
      total.current.value.length > 0
    ) {
      user ? navigate("/prealertar") : navigate("/registro");
    } else {
      toast.error("Por favor complete el siguiente formulario", {
        position: "top-center",
        theme: "dark"
      });
    }
  };


  const format = (e) => {
    var num = e.target.value
      .replaceAll(".", "")
      .replaceAll(" ", "")
      .replaceAll(",00", "")
      .replaceAll(",0", "");

    var num2 = num.split(/(?=(?:\d{3})+$)/).join(".");
    e.target.value = num2.replaceAll(",00", "").replaceAll(",0", "").replaceAll(",", "") + ",00";

    const cursorPosition = e.target.selectionStart;
    const commaPosition = e.target.value.indexOf(',');

    if (cursorPosition <= commaPosition) {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    } else {
      e.target.setSelectionRange(commaPosition, commaPosition);
    }
  };

  return (
    <div className="rounded border calculator">
      <div
        className="curr w-100 fs-5 bg-blue rounded mb-3"
        style={{ border: 0 }}
      >
        <p
          className="w-50 py-2 text-center bg-blue rounded"
          type="button"
          onClick={() => setFirst(true)}
          style={{
            opacity: first ? 0.7 : 1,
          }}
        >
          Calcular
        </p>
        <p
          className="w-50 py-2 text-center bg-blue rounded local"
          style={{ borderLeft: "2px solid white", opacity: !first ? 0.7 : 1 }}
          type="button"
          onClick={() => setFirst(false)}
        >
          Localizador
        </p>
      </div>
      {first ? (
        <>
          <div className="mt-3 px-2">
            <label>Destino</label>
            <div
              className="d-flex mt-1 bb"
              style={{ position: "relative", width: "100%" }}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img id="flag" ref={flag} alt={`Latinotransfer `} />
              <input
                type="text"
                className="form-control rounded-0"
                onInput={(e) => showList(e)}
                id="countryInp"
                ref={countryInp}
                autoComplete="off"
              />
              <div className="dropdown" style={{ position: "static" }}>
                <button
                  className="btn border dropdown-toggle rounded-0 h-100"
                  type="button"
                ></button>
                <ul className="dropdown-menu" id="country" ref={country}>
                  {filterData.length > 0
                    ? filterData.map((elem) => {
                      return (
                        <li
                        key={elem.NOM}
                          type="button"
                          className="py-1 px-2"
                          onClick={() =>
                            setCountry(`${elem.COUNTRY}`, `${elem.NOM}`)
                          }
                        >
                          {elem.COUNTRY}
                        </li>
                      );
                    })
                    : data.map((elem) => {
                      return (
                        <li
                          type="button"
                          className="py-1 px-2"
                          key={elem.COUNTRY}
                          onClick={() =>
                            setCountry(`${elem.COUNTRY}`, `${elem.NOM}`)
                          }
                        >
                          {elem.COUNTRY}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          <div className="mt-3 px-2">
            <label>Envía</label>
            <div
              className="bb d-flex mt-1"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                type="text"
                className="form-control"
                id="amountInp"
                inputMode="numeric"
                onInput={(e) => {
                  format(e);
                  Calculate();
                }}
                ref={amountInp}
              />
              <div className="curr">EUR</div>
            </div>
          </div>

          <div className="mt-3 px-2">
            <label>Recibe*</label>
            <div
              className="bb d-flex mt-1"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                type="text"
                className="form-control bg-white"
                id="gets"
                ref={gets}
                onChange={(e) => format(e)}
                disabled={true}
              />
              <div className="curr" id="curr2" ref={curr2}></div>
            </div>
          </div>
          <p className="text-muted mt-1 px-2">
            Comisión:{" "}
            <span className="fw-bold" id="totalFees" ref={totalFees}></span>
          </p>
          <p className="text-muted px-2">
            Tasa: 1 EUR = {" "}
            <span
              className="fw-bold"
              id="exchangerate"
              ref={exchangerate}
            ></span>
          </p>

          <div className="mt-3 px-2">
            <label>Total a pagar </label>
            <div
              className="bb d-flex mt-1"
              style={{ position: "relative", width: "100%" }}
            >
              <input
                type="text"
                className="form-control bg-white"
                id="total"
                ref={total}
                onInput={(e) => format(e)}
                disabled={true}
              />
              <div className="curr">EUR</div>
            </div>
          </div>
          <p className="text-muted px-2 small">
            *Resultado sujeto a tasas y tipos de cambio variables.
          </p>
          {btn && (
            <button onClick={Continue} className="continue">
              Continuar
            </button>
          )}
        </>
      ) : (
        <div className="mt-3">
          {status?.length === 0 ? (
            <>
              {" "}
              <label>Número de Órden</label>
              <input
                className="form-control mt-2 rounded"
                onChange={(e) => setId(e.target.value)}
                required
              />
              <p onClick={CheckStatus} className="continue" type="button">
                BUSCAR
              </p>
            </>
          ) : (
            <div className="text-center">
              <p
                className={`badge rounded-pill fs-6 py-2 px-3 ${CheckColor(
                  status
                )}`}
              >
                {status.toUpperCase()}
              </p>
              <button onClick={() => setStatus("")} className="continue">
                Volver
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default (Calculator);
