import React, { useState, useEffect } from "react";
import PageBanner from "../Components/PageBanner";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";

const Beneficiario = ({ }) => {
  const [benef, setBenef] = useState([]);
  const [show, setShow] = useState(false);
  const [neww, setNew] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [cuenta, setCuenta] = useState(true);


  const Fetch = async () => {
    const data2 = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/orders/userbenes",
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );

    const filterM = data2?.data?.orders.map((elem) => {
      return {
        id: elem._id,
        bbank: elem.bbank,
        bAccnumber: elem.bAccnumber,
        bname: elem.bname,
        cedula: elem.cedula,
      };
    });

    const isUnique = (arr, obj) => {
      return arr.some((elem) =>
        elem.bbank === obj.bbank &&
        elem.bAccnumber === obj.bAccnumber &&
        elem.cedula === obj.cedula
      );
    };

    const uniqueFilterM = filterM.filter((elem, index, self) => {
      return !isUnique(self.slice(0, index), elem);
    });

    setBenef(uniqueFilterM);
  };

  useEffect(() => {
    Fetch();
  }, []);

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };


  const HandleUpdate = async () => {
    if ((!cuenta && data.bAccnumber.length === 11) || (cuenta && data.bAccnumber.length === 20)) {
      if (neww) {
        setLoading(true)
        await axios.post(process.env.REACT_APP_BASE_URL + `/api/orders/addbene`,
          {
            ...data
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        )
        setShow(false)
      } else {
        setLoading(true)
        await axios.put(process.env.REACT_APP_BASE_URL + `/api/orders/updatebene/${data.id}`,
          {
            ...data
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        )
      }
      setShow(false)
    } else {
      toast.error(cuenta ? "CUENTA DEBE TENER 20  DíGITOS!" :
        "TELÉFONO DEBE TENER 11 DÍGITOS!", {
        position: "top-center",
        theme: "dark"
      });
    }
    Fetch()
    setLoading(false)
  }


  const DeleteBene = async (elem) => {
    document.getElementById(`del_${elem.id}`).innerHTML = "Enviando";
    await axios.post(process.env.REACT_APP_BASE_URL + `/api/orders/deletebene`,
      {
        id: elem.id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    )
    Fetch()
    document.getElementById(`del_${elem.id}`).innerHTML = "Borrar";
  }

  return (
    <>
      <PageBanner title={"Beneficiarios"} />
      <div className="Orderhistory table-responsive canvas">
        <button className="btn  mt-10 btn-primary d-block ms-auto"
          onClick={() => {
            setShow(true)
            setNew(true)
            setData({
              bbank: "BANESCO",
              bname: "",
              cedula: "",
              bAccnumber: "",
            })
          }}>Añadir</button>
        <table className="table mt-3 bg-white rounded shadow">
          <div className="filters"></div>
          <thead className="text-white bg-dark">
            <tr>
              <th scope="col">Beneficiario</th>
              {window.matchMedia("(min-width: 500px)").matches && (
                <th>Cuenta</th>
              )}
              <th scope="col">Cédula</th>
              <th scope="col">Banco</th>
              <th scope="col">Modificar</th>
            </tr>
          </thead>
          <tbody>
            {benef.map((elem, i) => {
              return (
                <tr key={i}>
                  <td>{elem.bname}</td>
                  {window.matchMedia("(min-width: 500px)").matches && (
                    <td>{elem.bAccnumber}</td>
                  )}
                  <td scope="col">{elem.cedula}</td>
                  <td>{elem.bbank}</td>
                  <td className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary w-auto mt-0 fs-6 py-1 me-2"
                      onClick={() => {
                        setNew(false)
                        setData(elem)
                        setCuenta(elem.bAccnumber.length === 20 ? true : false)
                        setShow(true)
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Corregir
                    </button>
                    <button
                      className="btn btn-danger w-auto mt-0 fs-6 py-1 me-2"
                      onClick={() => {
                        DeleteBene(elem)
                      }}
                      id={`del_${elem.id}`}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {show && (
          <div
            className="modal_bg d-flex justify-content-center 
          align-items-center"
          >
            <div
              className="modal"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      {"Añadir"}  Beneficiario
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setShow(false);
                        setCuenta(true)
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <label className="mt-3">Nombre y Apellido</label>
                    <input
                      className="form-control rounded mt-1"
                      type="text"
                      placeholder=""
                      id="bname"
                      onChange={handleChange}
                      value={data.bname}
                      required
                    />

                    <label className="mt-3">Cédula Beneficiario</label>
                    <input
                      className="form-control rounded mt-1"
                      type="text"
                      id="cedula"
                      onChange={handleChange}
                      placeholder=""
                      required={true}
                      value={data.cedula}
                      // inputmode="numeric"
                    />

                    <div className="d-flex mt-3  flex-wrap">
                      <div className="d-flex align-items-center col-lg-4 col-md-5 col-12">
                        <input type="radio" name="cuenta" className="mb-0"
                          checked={cuenta}
                          onChange={(e) => {
                            setCuenta(!cuenta)
                            setData({ ...data, bAccnumber: "" })
                          }
                          }
                        />
                        <label className="ms-2">Número de Cuenta</label>
                      </div>
                      <div className="d-flex ms-md-5 align-items-center">
                        <input type="radio" name="cuenta" className="mb-0"
                          checked={!cuenta}
                          onChange={(e) => {
                            setCuenta(!cuenta)
                            setData({ ...data, bAccnumber: "" })
                          }}
                        />
                        <label className="ms-2">Pago Móvil</label>
                      </div>
                    </div>

                    <input
                      className="form-control rounded mt-1"
                      type="text"
                      id="bAccnumber"
                      value={data.bAccnumber}
                      onChange={(e) => {
                        if (cuenta) {
                          if (e.target.value.length <= 20) {
                            handleChange(e)
                          }
                        } else {
                          if (e.target.value.length <= 11) {
                            handleChange(e)
                          }
                        }
                      }}
                      placeholder=""
                      required={true}
                      min={cuenta ? 20 : 11}
                      max={cuenta ? 20 : 11}
                    />

                    <div className="mt-3">
                      <label className="">Entidad Bancaria</label>
                      <div className="d-flex align-items-center rounded">
                        <div class="dropdown w-100">
                          <button
                            class="d-flex justify-content-between align-items-center px-3 py-2 border dropdown-toggle w-100 bg-white text-start"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            id="bbank"
                          >
                            {data.bbank}
                          </button>
                          <ul class="dropdown-menu w-100"
                            style={{ height: "200px", overflowY: "auto" }}>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANESCO
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              VENEZUELA
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              MERCANTIL
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              PROVINCIAL
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BNC
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANCARIBE
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              PAGO MÓVIL
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BICENTENARIO
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANCAMIGA
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              EXTERIOR
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              FONDO COMÚN
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANPLUS
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              VENEZOLANO DE CRÉDITO
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              SOFITASA
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              PLAZA
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              DEL TESORO
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              100% BANCO
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              ACTIVO
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              CARONÍ
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              DEL SUR
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANCRECER
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              INDUSTRIAL
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANDEX
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANCOEX
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANGENTE
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              INTERNACIONAL DE DESARROLLO
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              AGRÍCOLA
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              MI BANCO
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              IMCP
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANAVIH
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BANFANB
                            </li>
                            <li
                              class="dropdown-item"
                              type="button"
                              onClick={(e) =>
                                setData((prev) => ({
                                  ...prev,
                                  bbank: e.target.innerHTML,
                                }))
                              }
                            >
                              BOD
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn bg-p text-white"
                      data-bs-dismiss="modal"
                      disabled={loading}
                      onClick={() => {
                        HandleUpdate()
                      }}
                    >
                      {!neww ?
                        loading ? "Enviando" : "Actualizar"
                        :
                        loading ? "Enviando" : "Guardar"
                      }
                    </button>
                    <button
                      type="button"
                      className="btn text-white bg-secondary"
                      onClick={() => {
                        setShow(false)
                        setCuenta(true)
                      }}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default (Beneficiario);
